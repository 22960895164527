import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import NavBar from '../NavBar';
import Image from "../assets/image.png";
import Logo from "../assets/logo.png";
//import GoogleSvg from "../assets/icons8-google.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from 'js-cookie';
import styles from './Login.module.css'; // Import CSS module

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false); // State to manage button disable
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const userId = Cookies.get('userId');
    if (userId) {
      navigate('/users/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const lowerCaseEmail = email.toLowerCase();
    
    try {
      const response = await fetch('https://api.opnier.com/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: lowerCaseEmail, password }),
      });

      if (response.ok) {
        const data = await response.json();
        // Save user data in cookies for 15 days
        Cookies.set('userId', data.userId, { expires: 15 });
        Cookies.set('full_name', data.full_name, { expires: 15 });
        Cookies.set('my_referral_code', data.my_referral_code, { expires: 15 });
        Cookies.set('usr_verified', data.ver_status, { expires: 15 });

        setIsDisabled(true); // Disable button and inputs on success
        setError('');
        // Redirect to the dashboard
        navigate('/users/dashboard');
      } else {
        const data = await response.json();
        setError(data.error);
        setIsDisabled(false); // Ensure button is enabled if there's an error
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      setIsDisabled(false); // Ensure button is enabled if there's an error
    }
  };

  return (
    <div>
      <NavBar />
      <div className={styles.loginMain}>
        <div className={styles.loginLeft}>
          <img src={Image} alt="" />
        </div>
        <div className={styles.loginRight}>
          <div className={styles.loginRightContainer}>
            <div className={styles.loginLogo}>
              <img src={Logo} loading="lazy" alt="" />
            </div>
            <div className={styles.loginCenter}>
              <h2>Welcome back!</h2>
              <p>Please enter your details</p>
              <form onSubmit={handleSubmit} className={styles.formL}>
                <input 
                  type="email" 
                  id="email" 
                  value={email} 
                  placeholder="Email" 
                  onChange={(e) => setEmail(e.target.value)} 
                  disabled={isDisabled} // Disable input if the button is disabled
                />
                <div className={styles.passInputDiv}>
                  <input 
                    id="password" 
                    value={password} 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Password" 
                    onChange={(e) => setPassword(e.target.value)} 
                    disabled={isDisabled} // Disable input if the button is disabled
                  />
                  {showPassword ? 
                    <FaEyeSlash onClick={() => setShowPassword(!showPassword)} /> :
                    <FaEye onClick={() => setShowPassword(!showPassword)} />}
                </div>
                <div className={styles.loginCenterOptions}>
                  <div className={styles.rememberDiv}>
                    <input 
                      type="checkbox" 
                      id="remember-checkbox" 
                      disabled={isDisabled} // Disable checkbox if the button is disabled
                    />
                    <label htmlFor="remember-checkbox">
                      Remember me
                    </label>
                  </div>
                  <Link to="/users/forget-password" className={styles.forgotPassLink}>
                    Forgot password?
                  </Link>
                </div>
                {error && <div className="text-danger">{error}</div>}
                <div className={styles.loginCenterButtons}>
                  <button 
                    type="submit" 
                    disabled={isDisabled} // Disable button when isDisabled is true
                  >
                    {isDisabled ? 'Logging In...' : 'Log In'}
                  </button>
                  {/**<button type="button">
                    <img src={GoogleSvg} alt="" />
                    Log In with Google
                  </button>**/}
                </div>
              </form>
            </div>
            <p className={styles.loginBottomP}>
              Don't have an account? <Link to="/users/signup">Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
