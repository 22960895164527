import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import NavBar from './NavBar';
import ImageCrousel from './ImageCrousel';
import styles from './ReferAndEarn.module.css';

function ReferAndEarn() {
    const [referralCode, setReferralCode] = useState(null); // Use null to check if code is present
    const [userId, setuserId] = useState(null); // Use null to check if code is present

    useEffect(() => {
        const ver_code = Cookies.get('usr_verified');
        const userId = Cookies.get('userId');
        setuserId(userId || null); // Set null if no code is found
        if (ver_code === 'yes') {
            // Get referral code from cookies
            const code = Cookies.get('my_referral_code');
            setReferralCode(code || null); // Set null if no code is found
        }
    }, []);

    const handleShare = async () => {
        const shareUrl = `https://opnier.com/users/signup?ref=${referralCode}`;
        const shareText = `Check out Opnier! Use my referral code: ${referralCode} and earn upto ₹50. Just sign up here:\n\n`;

        const shareData = {
            title: 'Refer & Earn',
            text: shareText,
            url: shareUrl,
        };
    
        try {
            if (navigator.share) {
                await navigator.share(shareData);
            } else {
                alert('Web Share API is not supported in your browser.');
            }
        } catch (err) {
            console.error('Error sharing:', err);
        }
    };

    return (
        <div>
            <NavBar />
            <div className={styles.referAndEarn}>
                <h1>Refer & Earn</h1>
                {referralCode ? (
                    <>
                        <p>Your referral code is: <strong>{referralCode}</strong></p>
                        <button onClick={handleShare}>Share Your Referral Code</button>
                        <p>Invite friends to join Opnier and earn upto &#8377;200 instantly!</p>
                    </>
                ) : (
                    userId != null ? (
                        <p>Account not verified. Please check your email for the verification link.</p>
                    ) : 
                    <p>No referral code available. Please <Link to="/users/login">Log in</Link> to get your referral code.</p>
                )}
            </div>
            <ImageCrousel />
        </div>
    );
}

export default ReferAndEarn;
